exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-menu-index-tsx": () => import("./../../../src/pages/menu/index.tsx" /* webpackChunkName: "component---src-pages-menu-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-thankyou-index-tsx": () => import("./../../../src/pages/thankyou/index.tsx" /* webpackChunkName: "component---src-pages-thankyou-index-tsx" */),
  "component---src-pages-ticket-index-tsx": () => import("./../../../src/pages/ticket/index.tsx" /* webpackChunkName: "component---src-pages-ticket-index-tsx" */)
}

